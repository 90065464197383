import Vue from 'vue'
import Vuex from 'vuex'
import navData from './modules/navData'
import tabView from './modules/tabViews'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        navData,
        tabView
    }
})
