const setStorage = store => {
    return window.localStorage.setItem('tabView',JSON.stringify(store))
}
const tabViewInit = getTabView => {
    let storage = JSON.parse(window.localStorage.getItem('tabView'))
    if(!storage || storage.length<=0){
        setStorage([{name:'首页',path:'/dashboard'}])
    }
    return JSON.parse(window.localStorage.getItem('tabView'))
}
const state = {
    tabViewDatas:tabViewInit()
};
const getters = {

};

const actions = {
    addTabView({commit,state},param){
        commit('ADD_TAB_VIEW',param)
    }
};
let mutations = {
    ADD_TAB_VIEW(state,param){
        if(state.tabViewDatas.some(view => view.name === param.name)){
            return
        }
        state.tabViewDatas.push(param);
        setStorage(state.tabViewDatas)
    },
    CLOSE_TAB_VIEW_ITEM(state,param){
        setStorage(state.tabViewDatas)
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}