<template>
    <el-dialog
        class="edit-password-dialog"
        title="修改密码"
        :visible.sync="editPasswordDialogVisible"
        width="80%"
        @close="closeDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        center>
        <el-form ref="formDialog" status-icon :rules="rules" :model="form" label-width="80px">
            <el-form-item label="旧密码" prop="oldPassword">
                <el-input v-model="form.oldPassword" placeholder="请输入旧密码" show-password></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
                <el-input v-model="form.newPassword" placeholder="请输入新密码（长度在6-20，字母(必须有)和数字组合）" show-password></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="newPassword1">
                <el-input v-model="form.newPassword1" placeholder="再次输入新密码" show-password></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitEditPassword">提交</el-button>
        </span>
    </el-dialog>
</template>

<script>
import crypto from 'crypto'

export default {
    name: "exampleDialog",
    data(){
        let reg = /^(?=.*[0-9].*)(?=.*[A-Za-z].*)[A-Za-z\d]{6,20}$/
        let checkNewPass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('新密码不能为空'));
            } else if (!reg.test(value)) {
                callback(new Error('新密码不符合要求（长度在6-20，字母(必须有)和数字组合）!'));
            } else {
                callback();
            }

        };
        let checkNewPass1 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('确认密码不能为空'));
            } else if (value !== this.form.newPassword) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }

        };
        return {
            imgCode:'',
            editPasswordDialogVisible:false,
            form:{
                oldPassword:'',
                newPassword:'',
                newPassword1:''
            },
            codeText:'发送验证码',
            codeAble:true,
            rules: {
                newPassword1: [
                    { required:true,validator: checkNewPass1, trigger: 'blur' }
                ],
                newPassword: [
                    { required:true,validator: checkNewPass, trigger: 'blur' }
                ],
                oldPassword: [
                    { required:true,message:'旧密码不能为空', trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        openEditDialog(){
            this.editPasswordDialogVisible = true
        },
        getmd5(params){
            let md5 = crypto.createHash("md5");
            md5.update(params);
            return md5.digest('hex');
        },
        submitEditPassword(){
            this.$refs.formDialog.validate((valid) => {
                if (valid) {
                    this.$apiGet('lechun-baseservice/user/updatePassWord',{
                        oldPassword:this.getmd5(this.form.oldPassword),
                        newPassword:this.getmd5(this.form.newPassword),
                    }).then(res=>{
                        this.$alert('修改成功', '提示', {
                            confirmButtonText: '关闭',
                            type:'success',
                            showClose:false,
                            center:true,
                            callback: action => {
                                this.closeDialog()
                            }
                        });
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        closeDialog(){
            this.editPasswordDialogVisible = false

            this.$refs.formDialog.resetFields()
            this.$emit('closeEditDialog')
        }
    }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.edit-password-dialog{
    .img-input{
        .el-input-group__append{
            background: transparent;
        }
    }
}
</style>
