<template>
    <div id="app">
<!--        <router-view/>-->
        <DingOpen/>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>
<script>
import DingOpen from "./components/DingOpen/DingOpen";
export default({
    beforeCreate() {
        console.log('ff',this.$route)

    },
    components:{DingOpen},
    watch:{
        '$route'(to,from){
            //监听路由变化
            console.log('window',window.location.href,this.$route.path)
            let routePath = this.$route.path;
            if(routePath.indexOf('csms')>-1){
                document.title = '乐纯售后管理系统'
            }else if(routePath.indexOf('scrm')>-1){
                document.title = '乐纯SCRM管理系统'
            }else{
                document.title = '乐纯管理系统'
            }
        }
    },
})
</script>

<style lang="scss">

</style>
