import axios from 'axios'
import routerVue from '../router/index'
import {Notification} from 'element-ui'
import QS from 'qs'
axios.defaults.withCredentials = true;
axios.defaults.baseURL = `${window.location.protocol}${process.env.VUE_APP_API_PATH}`
// 请求超时时间
axios.defaults.timeout = 60000;
export let baseUrl=axios.defaults.baseURL;
//响应拦截器
// axios.interceptors.request.use(function (response) {
//     console.log(response)
// })
// axios.interceptors.response.use(function (response) {
//     if(response.status != 200){
//         Notification({
//             title:'错误',
//             type:'error',
//             message:'网络异常,请重试'
//         })
//     }else{
//         switch (response.data.status){
//             case 20112:
//                 //routerVue.push('/login')
//                 routerVue.push({
//                         //传递参数使用query的话，指定path或者name都行，但使用params的话，只能使用name指定
//                         path: '/login',
//                         query: {
//                             auth: "0"
//                         }
//                     }
//                 );
//                 break;
//             case 500:
//                 Notification({
//                     title:'错误',
//                     type:'error',
//                     message:response.data.message||'网络异常,请重试'
//                 })
//                 break;
//             case 40001:
//                 Notification({
//                     title:'错误',
//                     type:'error',
//                     message:response.data.message||'网络异常,请重试'
//                 })
//                 break;
//
//         }
//     }
//     return response;
//
// }, function (error) {
//     return Promise.reject(error);
// });

/**
 * 接口报错统一处理
 * **/
function catchErrorInfo(response) {
    /**
     * 接口错误处理
     * 20112未登录
     * 其他错误统一弹窗:错误码+错误信息(暂时)
     * **/
    if(response.status != 200){
        Notification({
            title:'错误',
            type:'error',
            message:'网络异常,请重试'
        })
    }else{
        switch (response.data.status){
            case 20112:
                //routerVue.push('/login')
                let returnPage = encodeURIComponent(window.location.href)
                window.location.href = `/login?auth=1&returnPage=${returnPage}`
                // routerVue.push({
                //         //传递参数使用query的话，指定path或者name都行，但使用params的话，只能使用name指定
                //         path: '/login',
                //     name:'Login',
                //         query: {
                //             auth: "0"
                //         }
                //     }
                // );
                break;
            default:
                Notification({
                    title:'错误',
                    type:'error',
                    message:response.data.message||'网络异常,请重试'
                })

        }
    }
}
/**
 *Get
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {String} path [请求path]
 */
export const apiGetRequest = (url, params={}) =>{
    params.pageRouterUrl = getRouterPageHash(window.location.href);

    return new Promise((resolve, reject) => {
        if(process.env.NODE_ENV == 'local'){
            var urlSplit = url.split("/");
            if(urlSplit[0] == 'lechun-baseservice'){
                urlSplit[0] = 'http://localhost:8866';
            } else if(urlSplit[0] == 'lechun-csms') {
                urlSplit[0] = 'http://localhost:8871';
            } else if(urlSplit[0] == 'lechun-cms') {
                urlSplit[0] = 'http://localhost:8865';
            }
            url = urlSplit.join('/');
            // export let baseUrl=axios.defaults.baseURL;
        }
        axios.get(url, {params: params})
            .then(res => {

                if(res.data.status==200){
                    resolve(res.data.value);
                }else{
                    reject(res.data)
                    catchErrorInfo(res)

                }
            })
            .catch(err => {
                console.log(err)
                Notification({
                    title:'错误',
                    type:'error',
                    message:err||'网络异常,请重试'
                })
                reject(err)
            })

    });
}
/**
 *Post
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {String} path [请求path]
 */
export const apiPostRequest = (url, params) =>{
    //params.pageRouterUrl = getRouterPageHash(window.location.hash);

    return new Promise((resolve, reject) => {
        console.log("process.env",process.env);
        if(process.env.NODE_ENV == 'local'){
            var urlSplit = url.split("/");
            if(urlSplit[0] == 'lechun-baseservice'){
                urlSplit[0] = 'http://localhost:8866';
            } else if(urlSplit[0] == 'lechun-csms') {
                urlSplit[0] = 'http://localhost:8871';
            } else if(urlSplit[0] == 'lechun-cms') {
                urlSplit[0] = 'http://localhost:8865';
            }
            url = urlSplit.join('/');
            console.log("after-url",url);
            // export let baseUrl=axios.defaults.baseURL;
        }
        //{headers: {'Content-Type': 'application/json;charset=UTF-8'}}
        axios.post(url, JSON.stringify(params),{headers: {'Content-Type': 'application/json;charset=UTF-8'}})
            .then(res => {
                console.log(res)
                if(res.data.status==200){
                    resolve(res.data.value);
                }else{
                    catchErrorInfo(res)
                }

            })
            .catch(err => {
                Notification({
                    title:'错误',
                    type:'error',
                    message:err||'网络异常,请重试'
                })
                reject(err)
            })
    });
}
/**
 *Post formData
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {String} path [请求path]
 */
export const apiPostFromDataRequest = (url, params) =>{
    //params.pageRouterUrl = getRouterPageHash(window.location.hash);

    return new Promise((resolve, reject) => {
        console.log("process.env",process.env);
        if(process.env.NODE_ENV == 'local'){
            var urlSplit = url.split("/");
            if(urlSplit[0] == 'lechun-baseservice'){
                urlSplit[0] = 'http://localhost:8866';
            } else if(urlSplit[0] == 'lechun-csms') {
                urlSplit[0] = 'http://localhost:8871';
            } else if(urlSplit[0] == 'lechun-cms') {
                urlSplit[0] = 'http://localhost:8865';
            }
            url = urlSplit.join('/');
            console.log("after-url",url);
            // export let baseUrl=axios.defaults.baseURL;
        }
        //{headers: {'Content-Type': 'application/json;charset=UTF-8'}}
        axios.post(url, QS.stringify(params),{headers: {'Content-Type': 'application/json;charset=UTF-8'}})
            .then(res => {
                console.log(res)
                if(res.data.status==200){
                    resolve(res.data.value);
                }else{
                    catchErrorInfo(res)
                }

            })
            .catch(err => {
                Notification({
                    title:'错误',
                    type:'error',
                    message:err||'网络异常,请重试'
                })
                reject(err)
            })
    });
}
/**
 *Post formData
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {String} path [请求path]
 */
export const apiUploadFile = (url, params) =>{
    //params.pageRouterUrl = getRouterPageHash(window.location.hash);

    return new Promise((resolve, reject) => {
        console.log("process.env",process.env);

        axios({
            url:url,
            method:'post',
            data:params,
            headers: {'Content-Type': 'multipart/form-data'}
        }).then((res) => {
            console.log(res)
            // resolve(res.data)
            if(res.status==200){
                resolve(res.data.value);
            }else{
                catchErrorInfo(res)
                reject(err)
            }

        })
            .catch(err => {
                Notification.error({
                    message:'错误',
                    description:err.message||'网络异常,请重试'
                })
                reject(err)
            })
    });
}
function getRouterPageHash(hash){
    let regRex = /^#\/(\w)*/g
    let returnUrl = ''
    let hashSet = hash.match(regRex)
    if(hashSet && hashSet[0].split('#/').length>1){
        returnUrl = hashSet[0].split('#/')[1]
    }
    return returnUrl
}
