<template>
    <div class="default-body customer-qw-wrap">
        <div class="user-info-qw">
            <el-tabs type="border-card" v-model="activeTab">
                <el-tab-pane label="用户信息" name="1">
                    <el-scrollbar :style="{height: containerHeight+'px'}">
                        <el-descriptions labelClassName="label-style" class="margin-top" :column="1" size="mini" border>
                            <template slot="extra">
                                <el-button v-if="qwPlatform&&!isFromSide" type="success" @click="openChat()" size="mini">打开聊天</el-button>
                                <el-button type="primary" @click="openPage('detail')" size="mini">用户详情</el-button>
                                <el-button type="danger" @click="openPage('tabPage')" size="mini">用户标签</el-button>
                            </template>
                            <el-descriptions-item label="自定义昵称">
                                <el-input size="mini" v-model="customerInfo.defineName" placeholder="请输入自定义昵称" @blur="saveDefineName"></el-input>
                            </el-descriptions-item>
                            <el-descriptions-item label="设置状态">
                                <el-select size="mini" v-model="customerInfo.isDelMe" placeholder="请选择" @change="saveSuccStatus">
                                    <el-option label="正常用户" :value="1"></el-option>
                                    <el-option label="客户已删除" :value="2"></el-option>
                                    <el-option label="免打扰" :value="10"></el-option>
                                    <el-option label="自己人" :value="20"></el-option>
                                </el-select>

                                <!--                        <el-checkbox v-model="customerInfo.isDelMe" @change="saveSuccStatus">客户已经删除我</el-checkbox>-->
                            </el-descriptions-item>


                            <el-descriptions-item label="昵称">
                                {{customerInfo.nickName||''}}
                            </el-descriptions-item>
                            <el-descriptions-item label="手机">
                                {{customerInfo.mobile||''}}
                            </el-descriptions-item>
                            <el-descriptions-item label="会员等级">
                                {{customerInfo.vipLevel||''}}
                            </el-descriptions-item>
                            <el-descriptions-item label="OPENID">
                                <el-button @click="copyText(customerInfo.openId||'')" style="" size="mini" type="text" icon="el-icon-document-copy"></el-button>
                                {{customerInfo.openId||''}}
                            </el-descriptions-item>
                            <el-descriptions-item label="CUSID">
                                <el-button @click="copyText(customerInfo.customerId||'')" style="" size="mini" type="text" icon="el-icon-document-copy"></el-button>
                                {{customerInfo.customerId||''}}
                            </el-descriptions-item>
                            <el-descriptions-item label="所属企微">
                                <p v-for="item in orderInfo.deleteTag"><span style="color: #2dacd1;font-size: 10px">● </span>{{item}}</p>
                            </el-descriptions-item>
                        </el-descriptions>
                        <el-descriptions labelClassName="label-style" class="margin-top" style="margin-top: 10px" :column="1" size="mini" border>
                            <el-descriptions-item label="订单数">
                                <p>总:<span v-text="orderInfo.orderCount"></span></p>
                                <p>实物:<span v-text="orderInfo.fOrderCount"></span></p>
                                <p>储值:<span v-text="orderInfo.cOrderCount"></span></p>
                                <p>活动:<span v-text="orderInfo.vOrderCount"></span></p>
                            </el-descriptions-item>
                            <el-descriptions-item label="客单价">
                                <p>总:<span v-text="orderInfo.unitOrderAmount"></span></p>
                                <p>实物:<span v-text="orderInfo.fUnitOrderAmount"></span></p>
                                <p>储值:<span v-text="orderInfo.cUnitOrderAmount"></span></p>
                                <p>活动:<span v-text="orderInfo.vUnitOrderAmount"></span></p>
                            </el-descriptions-item>
                            <el-descriptions-item label="首单渠道">
                                {{orderInfo.firstChannelClass||''}}
                            </el-descriptions-item>
                            <el-descriptions-item label="首单时间">
                                <p>下单:<span v-text="orderInfo.firstCreateTime"></span></p>
                                <p>签收:<span v-text="orderInfo.firstCompleteTime"></span></p>
                            </el-descriptions-item>
                            <el-descriptions-item label="末单渠道">
                                {{orderInfo.lastChannelClass||''}}
                            </el-descriptions-item>
                            <el-descriptions-item label="末单城市">
                                {{orderInfo.lastCity||''}}
                            </el-descriptions-item>
                            <el-descriptions-item label="末单时间">
                                <p>下单:<span v-text="orderInfo.lastCreateTime"></span></p>
                                <p>签收:<span v-text="orderInfo.lastCompleteTime"></span></p>
                            </el-descriptions-item>
                            <el-descriptions-item label="末单商品">
                                <p v-for="item in orderInfo.lastProductName"><span style="color: #55a532;font-size: 10px">● </span>{{item}}</p>

                            </el-descriptions-item>
                        </el-descriptions>
                        <el-descriptions labelClassName="label-style" class="margin-top" style="margin-top: 10px" :column="1" size="mini" border>
                            <el-descriptions-item label="常购渠道">
                                <p v-for="item in orderInfo.maxChannelClass"><span style="color: #55a532;font-size: 10px">● </span>{{item}}</p>
                            </el-descriptions-item>
                            <el-descriptions-item label="常购城市">
                                <p v-for="item in orderInfo.maxCity"><span style="color: #55a532;font-size: 10px">● </span>{{item}}</p>
                            </el-descriptions-item>
                            <el-descriptions-item label="常购小区">
                                {{orderInfo.communityName||''}}
                            </el-descriptions-item>
                            <el-descriptions-item label="常购品类">
                                <p v-for="item in orderInfo.maxProductType"><span style="color: #55a532;font-size: 10px">● </span>{{item}}</p>
                            </el-descriptions-item>
                            <el-descriptions-item label="常购品类2">
                                <p v-for="item in orderInfo.maxProductType2"><span style="color: #55a532;font-size: 10px">● </span>{{item}}</p>

                            </el-descriptions-item>
                            <el-descriptions-item label="常购单品">
                                <p v-for="item in orderInfo.maxProductName"><span style="color: #55a532;font-size: 10px">● </span>{{item}}</p>

                            </el-descriptions-item>
                        </el-descriptions>
                    </el-scrollbar>
                </el-tab-pane>
                <el-tab-pane label="SOP管理" name="2">
                    <el-scrollbar :style="{height: containerHeight+'px'}">
                        <div>
                            <el-date-picker
                                size="mini"
                                v-model="sopDate"
                                type="date"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                            </el-date-picker>
                            <el-button type="primary" size="mini" style="margin-left: 10px" @click="initSopList">查询</el-button>
                        </div>

                        <el-empty v-if="sopList.length<=0" :image-size="200"></el-empty>

                        <el-descriptions v-for="item in sopList" :labelStyle="{width:'70px'}" style="margin-top: 10px" class="margin-top" :column="1" size="mini" border>
                            <el-descriptions-item label="群组">
                                <div style="font-size: 14px;font-weight: 600">{{item.sceneName}}</div>
                                <div style="font-size: 10px;font-style: italic">
                                    <i style="color: #2dacd1" class="el-icon-s-order"></i>备注:{{item.sceneRemark}}
                                </div>
                                <div style="font-size: 10px;font-style: italic">
                                    <i style="color: #55a532" class="el-icon-s-promotion"></i>来自旅程:{{item.routeName}}
                                </div>
                            </el-descriptions-item>
                            <el-descriptions-item label="SOP状态">
                                <el-tag size="mini" effect="plain" type="danger" v-if="item.kefuUseStatus==0">待处理</el-tag>
                                <el-tag size="mini" effect="plain" type="success" v-if="item.kefuUseStatus==1">已处理</el-tag>
                                <el-tag size="mini" effect="plain" type="warning" v-if="item.kefuUseStatus==2">已发送</el-tag>
                                <el-tag size="mini" effect="plain" type="warning" v-if="item.kefuUseStatus==3">已回复</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item label="推送文案" v-if="item.pushText">
                                <div style="position: relative;padding-top: 29px;">
                                    <el-button @click="sendMessage(item)" :disabled="item.kefuUseStatus!=0" plain style="position: absolute;right: 0;top: 0" size="mini" :type="item.kefuUseStatus==0?'danger':'info'" round icon="el-icon-s-promotion">发送</el-button>
                                    <span style="display:block;font-weight: 600;margin: 5px 0">文案编号:{{item.materialCode}}</span>
                                    <p class="copy-text" slot="reference" v-html="item.pushText"></p>
                                </div>
                            </el-descriptions-item>
                            <el-descriptions-item label="非标文案" v-if="item.keyword">
                                <div style="position: relative;">
                                    <p class="copy-text" slot="reference" v-html="item.keyword"></p>
                                </div>
                            </el-descriptions-item>
                            <el-descriptions-item label="临时素材" v-if="item.mediaType">
                                <div style="position: relative;">
                                    <el-button @click="sendMessage(item)" :disabled="item.kefuUseStatus!=0" :style="{color:item.kefuUseStatus==0?'#e50011':'#cfcfcf'}" size="mini" type="text" icon="el-icon-chat-dot-round">发送</el-button>
                                </div>
                            </el-descriptions-item>

                            <el-descriptions-item label="小程序信息" v-if="item.materialType=='miniprogram'">
                                <div>
                                    <miniProgram :miniTitle="item.title" :miniImg="item.imgUrl">
                                        <el-button @click="sendMessage(item)" :disabled="item.kefuUseStatus!=0" :style="{color:item.kefuUseStatus==0?'#e50011':'#cfcfcf'}" size="mini" type="text" icon="el-icon-chat-dot-round">发送</el-button>
                                    </miniProgram>
                                </div>
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-scrollbar>

                </el-tab-pane>
                <el-tab-pane label="小程序商品" name="3" >
                    <el-scrollbar :style="{height: containerHeight+'px'}">

                        <el-radio-group size="mini" v-model="mediaTab" @change="changeTypeToggle">
                            <el-radio-button label="1">商城首页</el-radio-button>
                            <el-radio-button label="2">最近发送</el-radio-button>
                            <el-radio-button label="3">私域专享</el-radio-button>
                            <el-radio-button label="4">我的收藏</el-radio-button>
                        </el-radio-group>
                        <div>
                            <div style="margin: 10px 0;display: flex">
                                <el-select
                                    style="flex:1"
                                    :popper-append-to-body="false"
                                    v-model="searchValue"
                                    filterable
                                    remote
                                    clearable
                                    size="mini"
                                    reserve-keyword
                                    placeholder="请输入关键词"
                                    :remote-method="remoteMethod"
                                    :loading="searchLoading">
                                    <el-option
                                        v-for="item in selOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                                <el-button type="danger" plain size="mini" @click="searchSubmit">查询</el-button>
                            </div>
                            <el-scrollbar :style="{height:containerHeight-86+'px'}">
                                <div v-for="item in mediaTab==1?indexItemData:mediaData" class="mini-item">

                                    <div style="display: flex">
<!--                                        <div class="mini-item-check" @click="collect(item)">-->
<!--                                            <i v-if="item.collectStatus==1" style="color: #E6A23C" class="el-icon-star-on"></i>-->
<!--                                            <i v-else class="el-icon-star-off"></i>-->
<!--                                        </div>-->
                                        <img :src="item.imgUrl">
                                        <div class="mini-item-title">
                                            <el-tag effect="plain" :type="proTypeObj[item.type].status" size="mini" style="font-size: 9px">{{proTypeObj[item.type].name}}</el-tag>
                                            {{item.title}}
                                            <p>私域码：（ {{item.shareCode}}）</p>
                                            <p  @click="copyText(item.shortLink)"   v-if="item.shortLink">短链：{{item.shortLink}} <span style="color: #e50011;cursor: pointer"><i class="el-icon-document-copy"></i></span></p>
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: flex-end;margin-top: 10px">
                                        <el-button type="text" size="mini" plain class="mini-item-submit" @click="collect(item)"  :style="{color: item.collectStatus==1?'#E6A23C':''}"><i :class="item.collectStatus==1?'el-icon-star-on':'el-icon-star-off'"></i> {{item.collectStatus==1?'已':''}}收藏</el-button>
                                        <el-button type="text" size="mini" plain class="mini-item-submit" @click="pushMedia(item,'text')" :disabled="item.status==0"><i class="el-icon-s-management"></i> 获取短链</el-button>
                                        <el-button type="text" size="mini" plain class="mini-item-submit" @click="pushMedia(item,'miniprogram')" :disabled="item.status==0"><i class="el-icon-s-promotion"></i> 小程序</el-button>
                                    </div>

                                </div>
                                <el-empty v-if="mediaTab==1&&indexItemData.length<=0" description="暂无数据"></el-empty>
                                <el-empty v-if="mediaTab!=1&&mediaData.length<=0" description="暂无数据"></el-empty>
                                <div class="footer" v-if="mediaTab!=1">
                                    <el-pagination
                                        small
                                        background
                                        hide-on-single-page
                                        :current-page="miniProParam.currentPage||1"
                                        :page-size="miniProParam.pageSize"
                                        layout="total,prev, pager, next"
                                        :total="miniProParam.total"
                                        @current-change="handleMiniProPage">
                                    </el-pagination>
                                </div>
                            </el-scrollbar>


                        </div>
                    </el-scrollbar>


                </el-tab-pane>
            </el-tabs>
        </div>
    </div>

</template>
<script>
import defaultSec from '@c/defaultSection'
import {wxQw} from "@/api/wxQwConfig";
import miniProgram from "@c/scrm/publicComponent/miniProgram";
import tablePagination from '@c/tablePagination'


let wx = window.wx
let qwPlatform = navigator.userAgent.indexOf('wxwork')>-1?true:false

export default {
    data() {
        return {
            qwPlatform,
            activeTab:'1',
            mediaTab:'1',
            isFromSide:this.$route.query.partnerId?false:true,//是否是企微侧边栏
            customerInfo: {openId: ''},
            orderInfo: {},
            mediaEntity:{
                type:3,
                itemId:'',
                mediaType:4
            },
            formInline: {
                currentPage: 1,
                pageSize: 10,
                isOnLine: 0,
                nickName: '',
                mobile: '',
                openId: this.$route.query.partnerId|| '',
                customerId: '',
                qyuserId:'',
            },
            formParam: {
                pageSize: 20,
                currentPage: 1,
                materialCode: '',
                materialName: '',
                materialType: 'miniprogram',
                groupId: '',
                status: 1,
                firstSnedTime: null,
                date: null,
            },
            miniProParam:{
                itemId:'',
                pageSize: 100,
                total:0,
                currentPage: 1,
                mediaType:1
            },
            total:0,
            miniList:[],
            sopList:[],
            indexItemData:[],
            mediaData:[],
            siYuData:[],
            recentlySendData:[],
            collectData:[],
            searchValue:'',
            searchQueryList:[],
            selOptions:[],
            searchLoading:false,
            sopDate:new Date().Format("yyyy-MM-dd"),
            containerHeight:'900px',
            proTypeObj:{
                1:{
                    name:'促销',
                    status:'success'
                },
                4:{
                    name:'单品',
                    status:'danger'
                },5:{
                    name:'活动',
                    status:'warning'
                },
            }
        }
    },
    components: {
        defaultSec,miniProgram,tablePagination
    },
    created() {
        // this.initHeight()
    },
    mounted(){
        this.initHeight()
        // console.log("item1",wx.getSystemInfo());
        //this.initMiniList()
        //企微打开:config
        if(qwPlatform){
            wxQw().then(()=>{
                if(this.formInline.openId != ''){
                    this.getCustomerLineList();
                    this.initMiniProData()
                    this.initSearchData()
                }else{
                    wx.invoke('getCurExternalContact', {}, (res)=>{
                        if (res.err_msg == 'getCurExternalContact:ok') {
                            let userInfo = res;
                            console.log('userInfo',userInfo)
                            this.formInline.openId = userInfo.userId;
                            this.getCustomerLineList();
                            this.initMiniProData()
                            this.initSearchData()
                        } else {
                            this.formInline.openId="未知";
                        }
                    })
                }
            })
        }else{
            this.getCustomerLineList();
            this.initMiniProData()
            this.initSearchData()
        }
    },
    methods: {
        initHeight(){
            console.log(document.querySelector('.default-body').offsetHeight);
            this.containerHeight = document.querySelector('.default-body').offsetHeight-60
        },
        initSopList(){
            this.$apiGet('lechun-cms/scrmRoute/getQwServiceSop', { customerId:this.formInline.customerId,date:this.sopDate}).then(res => {
                console.log(res)
                this.sopList = res;
            })
        },
        initMiniList(){
            this.$apiGet('lechun-cms/scrmMaterial/materialPageList', this.formParam).then(res => {
                this.miniList = res.list;
                this.total = res.total
            });
        },
        handlePage(page){
            this.formParam.currentPage = page;
            this.initMiniList()
        },
        getCustomerLineList() {
            this.customerInfo.openId = this.formInline.openId;
            this.formInline.qyuserId = this.getCookie("qyuserId");
            this.$apiGet('lechun-cms/customer/getCustomerInfoWithQw', this.formInline).then(res => {
                this.customerInfo = res;
                if(res.isLogin==0){
                    let returnPage = encodeURIComponent(window.location.href)
                    window.location.href = `/login?auth=0&returnPage=${returnPage}`
                    return;
                }
                this.formInline.customerId = this.customerInfo.customerId;
                this.getOrderInfo();
                this.initSopList();
            })
        },
        saveDefineName(){
            this.$apiGet('lechun-cms/qiyeweixin/saveDefineName', {customerId:this.customerInfo.customerId,defineName:this.customerInfo.defineName}).then(res => {
                this.$notify({
                    title: '提示',
                    message: '保存成功',
                    type: 'success'
                });
            })
        },
      saveSuccStatus(){
        this.$apiGet('lechun-cms/qiyeweixin/saveSuccStatus', {customerId:this.customerInfo.customerId,isDelMe:this.customerInfo.isDelMe}).then(res => {

        })
      },
        getCookie(name){
          var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
          if (arr = document.cookie.match(reg))
            return unescape(arr[2]);
          else
            return null;
        },
        getOrderInfo() {
            this.$apiGet('lechun-bi/order/getCustomerData', this.formInline).then(res => {
                this.orderInfo = res;
                this.orderInfo = {
                    ...res,
                    deleteTag:res.deleteTag?res.deleteTag.split('/'):[],
                    lastProductName:res.lastProductName?res.lastProductName.split('),'):[],
                    maxChannelClass:res.maxChannelClass?res.maxChannelClass.split('),'):[],
                    maxCity:res.maxCity?res.maxCity.split('),'):[],
                    maxProductType:res.maxProductType?res.maxProductType.split('),'):[],
                    maxProductType2:res.maxProductType2?res.maxProductType2.split('),'):[],
                    maxProductName:res.maxProductName?res.maxProductName.split('),'):[],
                }

                //this.formInline.customerId = this.customerInfo.customerId;
            })
        },
        handleCmsDetail() {
            console.log(this.formInline.customerId)
            if (this.formInline.customerId == '') {
                this.$notify({
                    title: '错误',
                    message: '用户不存在,请重试',
                    type: 'error'
                });
            } else {
                this.$router.push({
                    path: '/userDetail/:id',
                    name: 'userDetail',
                    params: {id: this.formInline.customerId}
                })
            }
        },
        openPage(state){
            let cusId = this.formInline.customerId
            let url = ''
            if(state == 'detail'){
                url = window.location.origin+'/userDetail/'+cusId
            }
            if(state == 'tabPage'){
                url = window.location.origin+'/userTag?=customerId'+cusId
            }
            window.open(url,'_blank')
        },
        //发送消息
        sendMessage(item){
            console.log("item",item);
            if(item.kefuUseStatus==0){
                this.$apiGet('lechun-cms/scrmRoute/setProcessStatus',{'id':item.id}).then(res => {
                    console.log("res---",res)
                    if(qwPlatform){
                        let sendBody = {enterChat: true};
                        if(item.materialType == 'text'){
                            sendBody.msgtype = 'text';
                            sendBody.text = {content:item.pushText};
                        } else if(item.materialType == 'media'){
                            sendBody.msgtype = item.mediaType;
                            sendBody[item.mediaType] = {mediaid:item.materailOuterId};
                        }else if(item.materialType == 'link'){
                            sendBody.msgtype = item.materialType;
                            sendBody.news={
                                link:item.link,
                                title:item.title,
                                imgUrl:item.imgUrl,
                                desc:item.linkDesc
                            }
                        }else if(item.materialType == 'miniprogram'){
                            sendBody.msgtype = item.materialType;
                            sendBody.miniprogram={
                                appid:item.appId,
                                title:item.title,
                                imgUrl:item.imgUrl,
                                page:item.page
                            }
                        }
                        console.log('sendBody',sendBody);
                        wx.invoke('sendChatMessage', sendBody, (result)=> {
                            if (result.err_msg == 'sendChatMessage:ok') {
                                //发送成功
                                this.initSopList();
                            }
                        })
                    }else{
                        this.$message({
                            message: '浏览器不支持打开企微对话,请在企业微信打开',
                            type: 'warning'
                        });
                    }
                })
            }

        },
        pushMini(item){
            console.log("item",item);
            if(qwPlatform){
                //查询素材详情
                this.$apiGet('lechun-cms/scrmMaterial/getMaterialWithType', {id: item.id}).then(res => {
                    let sendBody = {enterChat: true};
                    let miniItem=res
                    if(item.materialType == 'miniprogram'){
                        sendBody.msgtype = miniItem.materialType;
                        let page=miniItem.page
                        if(item.ncp_bat!=''){
                            if(page.indexOf('ncp_bat')<0){
                                if(page.indexOf('?')>0){
                                    page=page+'&ncp_bat='+item.ncp_bat
                                }else {
                                    page=page+'?ncp_bat='+item.ncp_bat
                                }
                            }
                        }
                        sendBody.miniprogram={
                            appid:miniItem.appid,
                            title:miniItem.title,
                            imgUrl:miniItem.miniImgUrl,
                            page:page
                        }
                        console.log('sendBody',sendBody);
                        wx.invoke('sendChatMessage', sendBody, (result)=> {
                            if (result.err_msg == 'sendChatMessage:ok') {
                                //发送成功
                                this.$message({
                                    message: '已推送',
                                    type: 'success',
                                    duration:500
                                });
                            }else {
                                this.$message({
                                    message: '推送失败',
                                    type: 'warning',
                                    duration:500
                                });
                            }
                        })
                    }
                })

            }else{
                this.$message({
                    message: '浏览器不支持打开企微对话,请在企业微信打开',
                    type: 'warning'
                });
            }
        },
        pushMedia(item,type){
            console.log("item",item);
            if(qwPlatform) {
                if(type=='miniprogram'){
                    if(item.imgUrl==''){
                        this.$message({
                            message: '缺图片,请联系运营配置图片',
                            type: 'warning',
                            duration:500
                        });
                        return
                    }
                    let sendBody = {enterChat: true};
                    sendBody.msgtype = 'miniprogram';
                    sendBody.miniprogram = {
                        appid: item.appid||'wxccfc57d73bc81766',
                        title: item.title,
                        imgUrl: item.imgUrl,
                        page: item.page
                    }
                    console.log('sendBody', sendBody);
                    wx.invoke('sendChatMessage', sendBody, (result) => {
                        if (result.err_msg == 'sendChatMessage:ok') {
                            //发送成功
                            this.$message({
                                message: '已推送',
                                type: 'success',
                                duration:500
                            });
                            //保存最近发送
                            this.$apiGet('lechun-cms/scrmMaterial/saveMedia', {
                                type:item.type,
                                itemId:item.itemId,
                                mediaType:2
                            }).then(res => {
                                this.orderInfo = res;
                            })
                        } else {
                            this.$message({
                                message: '推送失败',
                                type: 'warning',
                                duration:500
                            });
                        }
                    })
                }

            }
            if(type=='text'){

                this.$apiGet('lechun-cms/urlScheme/getMiniSchemeSystemByPara',{miniPath:item.page}).then(res => {
                    console.log('t',item.shortLink)
                    this.$set(item,'shortLink',res.shortLink)
                    var input = document.createElement("textarea"); // 创建input对象
                    input.value = res.shortLink; // 设置复制内容
                    document.body.appendChild(input); // 添加临时实例
                    input.select(); // 选择实例内容
                    document.execCommand("Copy"); // 执行复制
                    document.body.removeChild(input); // 删除临时实例
                });
            }

        },
        //打开聊天
        openChat(e){
            let externalUserId = this.orderInfo.externalUserId
            console.log(externalUserId)
            try{
                wx.openEnterpriseChat({
                    // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
                    externalUserIds: externalUserId, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
                    groupName: '',  // 会话名称。单聊时该参数传入空字符串""即可。
                    chatId: "", // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
                    success: function(res) {
                        console.log(res)
                    },
                    fail: function(res) {
                        console.log(res);
                        if(res.errMsg.indexOf('function not exist') > -1){
                            alert('版本过低请升级')
                        }
                    }
                });
            }catch (e) {
                console.log(e)
            }

        },
        //复制文案
        copyText(text){
            console.log('te',text)
            var input = document.createElement("textarea"); // 创建input对象
            input.value = text; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            this.$message({
                type: 'success',
                message: '已复制到剪贴板'
            });
        },
        initMiniProData(){
            this.$apiGet('lechun-cms/scrmMaterial/getIndexItemList',{itemId:this.miniProParam.itemId,
                saleType:null}).then(res => {
                this.indexItemData = res
            });
        },
        initMiniProMediaData(){
            this.$apiGet('lechun-cms/scrmMaterial/getMediaItemList', this.miniProParam).then(res => {
                this.mediaData = res.list;
                this.miniProParam.total = res.total
            });
        },
        changeTypeToggle(val){
            this.miniProParam.mediaType = val
            if(val==1){
                this.initMiniProData()
            }else{
                this.initMiniProMediaData()
            }
        },
        handleMiniProPage(page){
            this.miniProParam.currentPage = page
            this.initMiniProMediaData()
        },
        //收藏
        collect(row){
            let type = this.miniProParam.mediaType
            let collectStatus = row.collectStatus

            if(collectStatus==1){//取消收藏
                this.$apiGet('lechun-cms/scrmMaterial/deleteMedia', {mediaId: row.mediaId}).then(res => {
                    console.log(res);
                    this.$message({
                        showClose: true,
                        message: '取消成功',
                        type: 'success',
                        duration:500
                    });
                    if(type==4){
                        this.initMiniProMediaData();
                    }else{
                        this.$set(row,'collectStatus',0)
                        if(type==1){
                            this.initMiniProData()
                        }
                    }
                });
            }else{
                this.$apiGet('lechun-cms/scrmMaterial/saveMedia', {
                    type:row.type,
                    itemId:row.itemId,
                    mediaType:4
                }).then(res => {
                    this.$message({
                        showClose: true,
                        message: '已收藏',
                        type: 'success',
                        duration:500
                    });
                    this.$set(row,'collectStatus',1)
                })
            }

        },
        //搜索匹配
        initSearchData(){
            this.$apiGet('lechun-cms/scrmMaterial/getSaleItemList', {
                pageSize: 100000,
                total:0,
                currentPage: 1,
                itemName:''}).then(res => {
                this.searchQueryList = res.list.map(item=>{
                    return {...item,value:item.itemId,label:item.saleTypeName+' | '+item.itemName}
                })
            });
        },
        remoteMethod(query) {
            console.log('query',query)
            if (query !== '') {
                this.searchLoading = true;
                setTimeout(() => {
                    this.searchLoading = false;
                    this.selOptions = this.searchQueryList.filter(item => {
                        return item.label.toLowerCase()
                            .indexOf(query.toLowerCase()) > -1;
                    });
                }, 200);
            } else {
                this.selOptions = [];
            }
        },
        querySearchAsync(queryString, cb) {
            console.log(queryString,cb)
            let restaurants = this.searchQueryList;
            var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                cb(results);
            }, 3000 * Math.random());
        },
        createStateFilter(queryString) {
            return (state) => {
                return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        searchSubmit(){
            this.miniProParam.itemId = this.searchValue
            if(this.miniProParam.mediaType==1){
                this.initMiniProData()
            }else{
                this.initMiniProMediaData()
            }
        }
    }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.customer-qw-wrap{
    background: #f2f2f2;
    height: 100%;
    overflow: auto;
    p{
        margin: 2px;
    }
    .el-select-dropdown{
        left: 0!important;
    }
    .el-form-item__content {
        font-size: 12px;
    }

    .el-form-item__label {
        color: #795da3;
        font-size: 13px;
    }

    .el-form-item--mini.el-form-item {
        margin-bottom: 8px;
    }

    .el-input {
        width: 99%;
    }
    .el-descriptions__header{
        margin-bottom: 9px;
    }
    .el-tabs--border-card{
        background: #ffffff;
        box-shadow: none;
        border: none;
    }
    .el-descriptions-item__label.is-bordered-label{
        background: #ffffff;
        color: #000000;
    }
    .el-tabs--border-card > .el-tabs__content{
        padding: 10px;
        border-left:2px solid #f2f2f2;
        box-sizing: border-box;
    }

    .label-style{
        width: 85px;
    }
    .copy-text{
        white-space: pre-line;
        margin: 0;
        line-height: 18px;
        background: #f2f2f2;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        padding: 6px;
        max-height: 130px;
        overflow-y: scroll;
      scrollbar-width: none;
    }
    .mini-item{
        //display: flex;
        //justify-content: space-between;
        //align-items: center;
        border-bottom: 1px solid #dfdfdf;
        padding: 10px 0;
        .mini-item-check{
            font-size: 20px;
            color: #999999;
        }
        img{
            width: 40px;
            height: 40px;
            display: block;
            margin: 0 12px 0 16px;
        }
        .mini-item-title{
            flex: 1;
            font-size: 12px;

            p{
                color: #999999;
                font-size: 10px;
            }
        }
        .mini-item-submit{
            //color: #1bb263;
            //font-size: 22px;
            margin: 0 10px;
        }
    }
}

</style>