<template>
    <div>
        <template v-if="clientWidth<500">
            <div style="position: absolute;top: 12px;z-index: 99">
                <el-popover
                    v-model="showMenu"
                    placement="right-end">
                    <asideMenu :isCollapse="false"  @closeAside="select"></asideMenu>
                    <span @click="showMenuFun" slot="reference" class="aside-switch">
                        <transition name="trans">
                            <i class="el-icon-s-unfold" :class="{'trans-enter-active':isCollapse}"></i>
                        </transition>
                    </span>
                </el-popover>
            </div>
        </template>
        <template v-else>
            <div style="position: absolute;top: 12px;z-index: 99">
                <span @click="navTab" class="aside-switch">
                    <transition name="trans">
                        <i class="el-icon-s-unfold" :class="{'trans-enter-active':isCollapse}"></i>
                    </transition>
                </span>
            </div>
            <el-aside :width="isCollapse?'64px':'170px'">
                <asideMenu :isCollapse="isCollapse" @closeAside="select"></asideMenu>
            </el-aside>
        </template>

    </div>

</template>

<script>
import asideMenu from '@c/asideMenu'
    export default {
        data() {
            return {
                styleObject:{},
                onRoutes:'',
                clientWidth:document.body.clientWidth,
                showMenu:false,
                isCollapse:false,
            }
        },
        components:{asideMenu},
        mounted(){
            window.addEventListener('resize', () => {
                console.log(1234563)
                this.clientWidth = document.body.clientWidth
            });
        },
        methods:{
            select(){
                this.showMenu = false
            },
            showMenuFun(){
                console.log(this.showMenu)
                // this.showMenu = !this.showMenu
            },
            navTab(){
                this.isCollapse = !this.isCollapse
            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss">
    @import "../assets/css/public";
    @import "../assets/css/mixin";
    $mainColor:#209e91;
    $asideFont:12px;
    $asideIconFont:12px;
    .el-scrollbar{
        height: 100%;
    }
    .el-aside{
        height: 100%;
        margin: 0;
        border-radius: 8px;
      transition:width .3s;
      overflow-x: visible!important;
      box-shadow: 0 10px 10px 0 rgba(0,0,0,.25);
        overflow-y: scroll!important;
    }
    .aside-wrap{
        width: $asideWidth;
        overflow-y: scroll;
        scrollbar-width: none;
        @include aside_bg_color();
        >.el-menu{
            border: none;
            border-radius: 8px;
            background: transparent;
            .el-menu-item{
                font-size: $asideFont;
                @include aside_font_color();
                i{
                    color: inherit;
                }
                &.is-active,&.is-active:hover,&.is-active:focus{
                    background: $mainColor;
                    color: #ffffff;
                }
                &:hover,&:focus{
                    color: $mainColor;
                    background: transparent;
                }
            }
            .el-submenu{
                >.el-menu{
                    @include aside_submenu_bg_color();
                    .el-menu-item{
                        padding: 0 0 0 50px !important;
                    }
                }
                .el-submenu__title{
                    i{
                        color: inherit;
                    }
                    font-size: $asideFont;
                    @include aside_font_color();
                    &:hover{
                        color: $mainColor;
                        background: transparent;
                    }
                }
                &.is-active{
                    .el-submenu__title,.el-submenu__title i{
                        color: $mainColor;
                    }
                }
            }
        }
    }
    .aside-mini-wrap{
        width: $asideMiniWidth;
    }
    .aside-switch{
        margin-right: 18px;
        font-size: 24px;
        color: #209e91;
        cursor: pointer;
    }
    .iconfont{
        display: inline-block;
    }
    .trans-enter-active{
        animation: rotateT .5s ease forwards;
    }
    @keyframes rotateT {
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(90deg);
        }
    }

</style>
