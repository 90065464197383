import {apiGetRequest} from '@/api/http'
let wx = window.wx
let agentConfigSucceed = false
async function wxQw(){
    if(agentConfigSucceed===true){
        return
    }
    return new Promise(async (resolve,reject)=>{
        await waitForAgentConfigReady()
        console.log('agent ready')
        const urlNow = encodeURIComponent(window.location.href);
        let body = {
            url: urlNow,
            timestamp: 1000,
            nonceStr: Math.floor(Math.random() * 100000000000000),
            type: "agent_config"
        };
        apiGetRequest('lechun-cms/distributor/getQwConfig', body).then(res => {
            console.log('wx.agent',wx.agentConfig)
            console.log('wx.ready',wx.ready)
            wx.agentConfig({
                corpid: res.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
                agentid: res.agentid, // 必填，企业微信的应用id （e.g. 1000247）生产环境
                timestamp: res.timestamp, // 必填，生成签名的时间戳
                nonceStr: res.nonceStr, // 必填，生成签名的随机串
                signature: res.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
                jsApiList: [
                    'openUserProfile',
                    'previewFile',
                    'getCurExternalContact',
                    'openEnterpriseChat',
                    'sendChatMessage',
                ], // 必填
                success(result) {
                    console.log('sus',result)
                    resolve(wx)

                },
                fail(err) {
                    console.log('err',err)
                    if (err.errMsg.indexOf('function not exist') > -1) {
                        alert('版本过低请升级');
                    }
                }
            });


        })
    }).then(res=>{
        agentConfigSucceed = true
        return res
    },err=>{
        console.log('agent err')
    })
}
function waitForAgentConfigReady(){
    if(wx.agent !==undefined){
        return
    }
    return new Promise(resolve => {
        detectAgentConfig(resolve)
    })
}
function detectAgentConfig(resolve){
    console.log('detect')
    if(wx.agentConfig!==undefined){
        resolve()
        return
    }
    setTimeout(()=>{
        detectAgentConfig(resolve)
    })
}
export {wxQw}

