
const state = {
    navIsCollapse:false,
    menuList:[],
    menuSelectSystem:0
};
const getters = {

};

const actions = {

};
let mutations = {
    NAV_COLLAPSE(state,param){
        state.navIsCollapse = param
    },
    MENU_LIST(state,param){
        console.log(param)
        state.menuList = param
    },
    MENU_SELECT_SYSTEM(state,param){
        state.menuSelectSystem = param.index
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}