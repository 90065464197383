<template>
    <div class="chat-miniprogram">
        <p class="title"><el-avatar style="margin-right: 6px" size="small" src="http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg"></el-avatar> 乐纯</p>
        <p>{{miniTitle}}</p>
        <img class="img" :src="miniImg">
        <div class="mini-bottom">
            <span class="info">{{miniQiWei}}</span>
            <slot></slot>
        </div>
    </div>
</template>
<script>
 export default {
     props:{
         miniTitle:{
             type:String,
             default:'乐纯'
         },
         miniQiWei:{
             type:String,
             default:''
         },
         miniImg:{
             type:String,
             default:'http://lechunbucket.oss-cn-beijing.aliyuncs.com/kboneMiniResource/public/lechun-logo.jpg'
         }
     },
     data(){
         return {

         }
     }
 }
</script>
<style lang="scss" rel="stylesheet/scss">
.chat-miniprogram{
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 10px;
    width: 200px;
    .title{
        display: flex;
        align-items: center;
        font-size: 10px
    }
    .img{
        width: 100%;
        height: 150px;
        display: block;
        margin: 5px 0;
    }
    .mini-bottom{
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #dddddd;
        padding-top: 6px;
        align-content: center;
        align-items: center;
        .info{
            font-size: 10px;
            display: block;
        }
    }
}
</style>