<template>
    <el-dialog
        class="forget-password-dialog"
        title="修改密码"
        :visible.sync="forgetPasswordDialogVisible"
        width="80%"
        @close="closeDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        center>
        <el-form ref="formDialog" status-icon :rules="rules" :model="form" label-width="80px">
            <el-form-item label="手机号" prop="mobile">
                <el-input v-model="form.mobile" placeholder="请输入验证手机号"></el-input>
            </el-form-item>
            <el-form-item label="图片验证" prop="imgValidateCode">
                <el-input class="img-input" v-model="form.imgValidateCode" placeholder="请输入图片验证码">
                    <img  slot="append" @click="getCaptcha" style="width: auto;height: 38px;display: block" :src="imgCode"/>
                </el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="validateCode">
                <el-input v-model="form.validateCode" placeholder="请输入验证码">
                    <el-button slot="append" :disabled="!codeAble" @click="getCode">{{ codeText }}</el-button>
                </el-input>
            </el-form-item>

            <el-form-item label="新密码" prop="pwd">
                <el-input v-model="form.pwd" placeholder="请输入新密码" show-password></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitForgetPassword">提交</el-button>
        </span>
    </el-dialog>
</template>

<script>
import crypto from 'crypto'

export default {
    name: "exampleDialog",
    data(){
        return {
            imgCode:'',
            forgetPasswordDialogVisible:false,
            form:{
                mobile:'',
                imgValidateCode:'',
                validateCode:'',
                pwd:''
            },
            codeText:'发送验证码',
            codeAble:true,
            rules: {
                mobile: [
                    { required:true,message:'手机号不能为空', trigger: 'blur' }
                ],
                imgValidateCode: [
                    { required:true,message:'图片验证码不能为空', trigger: 'blur' }
                ],
                validateCode: [
                    { required:true,message:'验证码不能为空', trigger: 'blur' }
                ],
                pwd: [
                    { required:true,message:'密码不能为空', trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        openForgetDialog(){
            this.forgetPasswordDialogVisible = true
            this.getCaptcha()
        },
        getCaptcha(){
            this.$apiGet('lechun-baseservice/auth/captcha',{}).then(res=>{
                console.log(res)
                this.imgCode = res.image||''
            })
        },
        getCode(){
            this.$apiGet('lechun-baseservice/auth/sendMobileValidateCode',{mobile:this.form.mobile,imgValidateCode:this.form.imgValidateCode}).then(res=>{
                console.log(res)
                this.countDown()
            })
        },
        countDown(){
            this.codeText = '重新获取59s'
            this.codeAble =  false
            let n = 59
            let setIn = setInterval(()=>{
                n--
                console.log(n)
                this.codeText = `重新获取${n}s`
                if(n<=0){
                    clearInterval(setIn)
                    this.codeAble = true
                    this.codeText = '重新获取'
                }
            },1000)
        },
        getmd5(params){
            let md5 = crypto.createHash("md5");
            md5.update(params);
            return md5.digest('hex');
        },
        submitForgetPassword(){
            this.$refs.formDialog.validate((valid) => {
                if (valid) {
                    this.$apiGet('lechun-baseservice/auth/findPwd',{
                        mobile:this.form.mobile,
                        validateCode:this.form.validateCode,
                        pwd:this.getmd5(this.form.pwd)}).then(res=>{
                        console.log(res)
                        this.closeDialog()

                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        closeDialog(){
            this.forgetPasswordDialogVisible = false

            this.$refs.formDialog.resetFields()
            this.$emit('closeDialog')
        }
    }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.forget-password-dialog{
    .img-input{
        .el-input-group__append{
            background: transparent;
        }
    }
}
</style>
