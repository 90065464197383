<template>
    <div v-if="showDing" class="ding-open-wrap">
        <span>复制链接到浏览器打开</span>
        <span class="btn" @click="handleCopy(url)">复制</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            url:window.location.href,
            showDing:false
        }
    },
    mounted(){
        console.log(123,this.$route,this.$route.query.returnPage)

        if(this.isInDingTalk()){
            this.showDing = true
            let returnPage = this.$route.query.returnPage
            if(returnPage){
                this.url = decodeURIComponent(returnPage)
            }else{
                this.url = window.location.href
            }
        }else{
            this.showDing =false
        }

    },
    methods: {
        isInDingTalk(){
            return /DingTalk|DingTalkBrowser/.test(navigator.userAgent);
        },
        handleCopy(text){
            console.log(text,this)
            var input = document.createElement("textarea"); // 创建input对象
            input.value = text; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            this.$message({
                type: 'success',
                message: '已复制到剪贴板'
            });
        }
    }
}
</script>


<style scoped lang="scss">
.ding-open-wrap{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
    line-height: 20px;
    background: rgba(32,158,145,.6);
    z-index: 99;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    .btn{
        padding: 1px 10px;
        margin: 0 15px;
        font-size: 10px;
        border-radius: 5px;
        cursor: pointer;
        background: #e50011;
        color:#ffffff;
        font-weight: bold;
    }
    .success-tips{
        color: #333333;
        margin-left: 20px;
    }
}
</style>