<template>
    <el-header>
        <div class="header-wrap">
            <span class="logo">{{ defaultName }}平台 <i>lepur</i></span>
            <div class="header-content">
<!--                <h3>-->
<!--                    <span @click="navTab" class="aside-switch">-->
<!--                        <transition name="trans">-->
<!--                            <i class="el-icon-s-unfold" :class="{'trans-enter-active':isCollapse}"></i>-->
<!--                        </transition>-->
<!--                    </span>-->
<!--                </h3>-->
                <div class="header-info">
                    <div>
<!--                        <template v-for="(item,index) in menuList">-->
<!--                            <el-button  @click="selectSystem(item.resourceHtmlUrl)" size="mini" :type="defaultSelectSystem(item)?'primary':'info'" style="margin-right: 10px">● {{ item.parentResourceName }} ➤</el-button>-->
<!--                        </template>-->
                        <el-button  @click="selectSystem()" size="mini" type="primary" style="margin-right: 10px"><i class="el-icon-s-home" style="font-size: 14px"></i></el-button>

                    </div>
                    <el-dropdown placement="bottom" @command="handleTheme">
                        <el-button class="theme-wrap" size="mini" :icon="theme=='dark'?'el-icon-moon':'el-icon-sunny'"></el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="white"><i class="el-icon-sunny"></i>white</el-dropdown-item>
                            <el-dropdown-item command="dark"><i class="el-icon-moon"></i>dark</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-popover
                        placement="bottom"
                        width="240"
                        trigger="click">
                        <div class="popo-content">
                            <img class="avastar" src="../assets/images/face1.jpg">
                            <h3>{{user.userNick}}</h3>
                            <p>{{user.userName}}</p>
                            <div>
                                <el-button type="primary" size="mini" plain @click="loginOut">退出</el-button>
                            </div>
                        </div>
                        <div slot="reference" style="display: flex;margin-left: 20px;cursor: pointer">
                            <img class="avastar" src="../assets/images/face1.jpg">
                            <p class="user-name">{{user.userNick}}</p>
                        </div>
                    </el-popover>
                </div>

            </div>
        </div>
    </el-header>
</template>

<script>

    //定义组件
    export default{
        data() {
            return {
                user: {
                    userNick: '',
                    groupName: '',
                    userId:'',
                    userName:''
                },
                defaultName:'CSMS',
                theme:'white',
                centerDialogVisible: false,
            }
        },
        computed:{
            isCollapse:function(){
                return this.$store.state.navData.navIsCollapse
            },
            menuList:function(){
                return this.$store.state.navData.menuList
            },
        },
        //挂载时候执行
        mounted() {
            console.log(window.localStorage.getItem('themelc'))
            this.theme = window.localStorage.getItem('themelc')||'white'
            document.documentElement.setAttribute('data-theme', this.theme);
            this.defaultName = this.$route.path.indexOf('scrm')>-1?'SCRM':'CSMS'
            this.$apiGet('lechun-baseservice/auth/getUserDetail').then((res) => {
                console.log(res);
                this.user = res;
                document.cookie="userId="+this.user.userId+";domain=lechun.cc";
                document.cookie="userName="+this.user.userName+";domain=lechun.cc";
                document.cookie="userNick="+this.user.userNick+";domain=lechun.cc";
                document.cookie="groupName="+this.user.groupName+";domain=lechun.cc";
            });

            window.onresize = function () {
                if(document.body.clientWidth<=430){
                    this.$store.commit('NAV_COLLAPSE',true)
                }else{
                    this.$store.commit('NAV_COLLAPSE',false)
                }
            }.bind(this)
        },
        methods:{
            handleCommand(command){
               if(command=="logout"){
                   window.localStorage.clear()
                   let keys = document.cookie.match(/[^ =;]+(?=\=)/g);
                   if(keys) {
                       for(let i = keys.length; i--;)
                           document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
                   }
                   logout().then((res) => {
                       if(res.data.status==200){
                           this.$message({
                               showClose: true,
                               message: '退出登录',
                               type: 'success'
                           });
                           this.$router.push('login')
                       }else{
                           this.$message({
                               showClose: true,
                               message: res.data.message,
                               type: 'error'
                           });
                       }
                   })
               }else if(command=="updatePassword"){
                   this.$router.push('password');
               }
            },
            navTab(){
                this.$store.commit('NAV_COLLAPSE',!this.$store.state.navData.navIsCollapse)
            },
            defaultSelectSystem(item){
                let routePath = this.$route.path;
                return routePath.indexOf(item.resourceHtmlUrl)>-1?true:false
            },
            selectSystem(){
                window.open('https://portal.lechun.cc','_blank')
            },
            loginOut(){
                window.localStorage.clear()
                let keys = document.cookie.match(/[^ =;]+(?=\=)/g);
                if(keys) {
                    for(let i = keys.length; i--;)
                        document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
                }
                this.$apiGet('lechun-baseservice/auth/logout').then(res=>{
                    console.log(res)
                    this.$message({
                        showClose: true,
                        message: '退出成功',
                        type: 'success'
                    });
                    console.log('location',window.location.href)
                    let returnPage = encodeURIComponent(window.location.href)
                    window.location.href = `/login?auth=1&returnPage=${returnPage}`
                })
            },
            handleTheme(command){
                if(command=='dark'){
                    document.documentElement.setAttribute('data-theme', 'dark' );
                }else{
                    document.documentElement.setAttribute('data-theme', 'white' );
                }
                this.theme = command
                window.localStorage.setItem('themelc',command)

            },
            getCookie(name){
                var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
                if (arr = document.cookie.match(reg))
                    return unescape(arr[2]);
                else
                    return null;
            },
        }
    }

</script>
<style lang="scss" rel="stylesheet/scss" scoped>
    @import "../assets/css/public";
    @import "../assets/css/mixin";
    $write:#ffffff;
    .el-header{
        padding: 0;
    }
    .header-wrap{
        position: fixed;
        top:0;
        left: 0;
        z-index: 9;
        width: 100%;
        height: $headerHeight;
        @include header_bg_color();
        display: -webkit-box;
        -webkit-box-pack: justify;
        -webkit-box-align: center;
        box-shadow: 0 1px 6px  rgba(0,0,0,.3);
    }
    .logo{
        display: block;
        width: 210px;
        line-height: $headerHeight;
        text-align: center;
        font-weight: bold;
        font-size:24px;
        i{
            font-size: 10px;
        }
    }
    .logo-mini{
        width: $asideMiniWidth;
        font-size: 20px;
    }
    .header-content{
        -webkit-box-flex: 1;
        display: -webkit-box;
        -webkit-box-pack: justify;
        height: $headerHeight;
        h3{
            line-height: $headerHeight;
            margin: 0;
        }
    }
    .header-info{
        -webkit-box-flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 20px;
        position: relative;

    }
    .user-name{
        font-size: 10px;
        margin-left: 6px;
    }
    .header-info img{
        width: 36px;
        height: 36px;
        display: block;
        border-radius: 20px;
    }
    .info-user{
        margin-left: 10px;
        font-size: 12px;
        /*<!--color: $write;-->*/
    }
    .aside-switch{
        margin-right: 18px;
        font-size: 24px;
        color: #209e91;
    }
    .iconfont{
        display: inline-block;
    }
    .trans-enter-active{
        animation: rotateT .5s ease forwards;
    }
    @keyframes rotateT {
        0%{
            transform: rotate(0deg);
        }
        100%{
            transform: rotate(90deg);
        }
    }
    .popo-content{
        img{
            width:  70px;
            height: 70px;
            border-radius: 36px;
        }
        text-align: center;
    }
    .avastar{
        border: 2px solid #209e91;
    }
    $themeW:20px;
    .theme-wrap{
        width: $themeW;
        height: $themeW;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0;
        cursor: pointer;
        font-size: 14px;
        text-align: center;
        @include theme_show()
    }
</style>
