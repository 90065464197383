<template>
    <el-breadcrumb separator="/" class="tab-view">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-menu"></i> 首页</el-breadcrumb-item>
        <el-breadcrumb-item v-if="tabPath!='/dashboard'" :to="{ path: tabPath}">{{tabName}}</el-breadcrumb-item>
    </el-breadcrumb>
</template>

<script>
    export default{
        data(){
            return {
                tabPath:this.$route.path,
                tabName:this.$route.meta.title
            }
        },
        watch:{
            $route(){
                this.tabPath = this.$route.path
                this.tabName = this.$route.meta.title
            }
        },
        computed:{
            tabViews(){
                return this.$store.state.tabView.tabViewDatas
            }
        },
    }
</script>

<style lang="scss" rel="stylesheet/scss">
    .tab-view{
        padding: 13px 0;
        border-bottom: #3b4761;
        /*box-shadow: 0 1px 3px 0 rgba(0,0,0,0.12), 0 0 3px 0 rgba(0,0,0,0.04);*/
        font-size: 12px !important;
        .el-breadcrumb__inner{
            color: #ffffff !important;
        }
    }
</style>