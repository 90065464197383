import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {downloadImage} from './api/downloadImage'
import * as echarts from 'echarts'

import {apiGetRequest,apiPostRequest,baseUrl,apiPostFromDataRequest,apiUploadFile} from '@/api/http'

import ElementUi from 'element-ui'
import '@/assets/css/theme-variables.scss'
import '@/assets/css/common.scss'
import '@/assets/iconfont/iconfont.css'
Vue.config.productionTip = false
Vue.use(ElementUi);
Vue.prototype.$apiGet = apiGetRequest;
Vue.prototype.$apiPost = apiPostRequest;
Vue.prototype.$apiFormPost = apiPostFromDataRequest;
Vue.prototype.$apiUpload = apiUploadFile;
Vue.prototype.$baseUrl=baseUrl;
Vue.prototype.$echarts=echarts;
Vue.prototype.$downloadImage=downloadImage;

//修改日期原型，设置日期格式，new Date皆可用
Date.prototype.Format = function (fmt) { //author: meizz
    var o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "h+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

