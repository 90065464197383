<template>
    <div class="table-pagination">
        <slot></slot>
        <div class="footer" :data-j="hidePage" v-if="!hidePage">
            <el-pagination
                    small
                    background
                    hide-on-single-page
                    :current-page="currentPage||1"
                    :page-size="pageSize"
                    layout="total,prev, pager, next"
                    :total="total"
                    @current-change="handlePage">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default{
        props:['total','pageSize','currentPage','hidePage'],
        methods:{
            handlePage(page){
                this.$emit('handlePage',page)
            }
        }
    }
</script>

<style scoped lang="scss" rel="stylesheet/scss">
    .table-pagination{
        .footer{
            padding: 15px 0 ;
            display: flex;
            justify-content: flex-end;
        }
    }
</style>