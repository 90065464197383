<template>
    <section class="default-section-wrap">
        <div v-if="title" class="default-header" @click="returnHistory(returnState)">
            <h3>{{title}}</h3>
        </div>
        <slot v-else name="title"></slot>

        <div class="default-body" :class="isFlexSection?'body-flex':''">
            <template v-if="isDisableScroll">
                <slot></slot>
            </template>
            <el-scrollbar v-else>
                <slot></slot>
            </el-scrollbar>
        </div>

    </section>
</template>

<script>
    export default{
        props:['title','returnState','isDisableScroll','isFlexSection'],
        methods:{
            returnHistory(returnState){
                if(returnState){
                    window.history.go(-1)
                }
            }
        }
    }
</script>

<style scoped lang="scss" rel="stylesheet/scss">
  @import "../assets/css/mixin";
    .default-section-wrap{
      height: 100%;
        @include default_section_bg_color();
        background-position: 0 0;
        background-size: cover;
        border: none;
        border-radius: 5px;
        position: relative;
        //margin-bottom: 24px;
        //padding-bottom: 15px;

        .default-header{
            border-bottom: 1px solid rgba(0,0,0,.12);
            font-size: 14px;
            padding: 4px 15px;
            box-shadow: 0 1px 0 0 rgba(255,255,255,.12);
            box-sizing: border-box;
            h3{
                margin: 0;
                font-size: 14px;
                @include default_section_title_font_color();
            }
        }
        .default-body{
            margin: 10px 10px 0;
            padding: 10px;
            box-sizing: border-box;
            background: #ffffff;
            border-radius: 5px;
          height: calc(100% - 50px);

        }
        .body-flex{
          background: transparent;
          padding: 0;
        }
    }
</style>