<template>

        <defaultSec :title="'< 返回'" :returnState="true">
            <keep-alive>
                <div class="user-info-link">
                    <el-table border :data="customerInfo">
                        <el-table-column label="客户信息">
                            <template slot="header" slot-scope="scope">
                                <span>客户信息</span>
                            </template>
                            <template slot-scope="scope">
                                <el-form label-width="80px" size="mini">
                                    <div class="block"><el-avatar :size="50" :src="scope.row.headImageUrl"></el-avatar></div>
                                </el-form>
                                <el-row :gutter="20">
                                    <el-col :xs="24" :sm="12">
                                        <el-form :model="scope.row" class="demo-form-inline" label-width="80px" size="mini">
                                            <el-form-item label="昵称">
                                                <el-input v-model="scope.row.nickName" placeholder="无" :disabled="true"></el-input>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                    <el-col :xs="24" :sm="12">
                                        <el-form :model="scope.row" class="demo-form-inline" label-width="80px" size="mini">

                                            <el-form-item label="手机" class="red">
                                                <el-input v-model="scope.row.mobile" placeholder="无" type="number" maxlength="11" :disabled="true"></el-input>
                                            </el-form-item>
                                        </el-form>
                                    </el-col>
                                </el-row>
                                <el-form label-width="80px" size="mini">
                                    <el-form-item label="OPENID">
                                        <el-input v-model="scope.row.openId" placeholder="无" :disabled="true"></el-input>
                                    </el-form-item>
                                    <el-form-item label="CUSID">
                                        <el-input v-model="scope.row.customerId" placeholder="无" :disabled="true"></el-input>
                                    </el-form-item>
                                </el-form>
                                <el-form label-width="80px" size="mini">
                                    <el-form-item label="">
                                        <el-button type="success" size="mini" class="header-btn" @click="handleCmsDetail">查看详情</el-button>
                                        <el-link :href="getLink(1)" target="_blank">
                                            <el-button type="primary" size="mini" class="header-btn">查看详情(新窗口)</el-button>
                                        </el-link>
                                    </el-form-item>
                                    <el-form-item label="">
                                        <el-link :href="getLink(2)" target="_blank">
                                            <el-button type="primary" size="mini" class="header-btn">查看详情(新窗口ERP)</el-button>
                                        </el-link>
                                    </el-form-item>
                                </el-form>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </keep-alive>
        </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    export default {
        data() {
            return {
                customerInfo:[{}],
                formInline: {
                    currentPage: 1,
                    pageSize: 10,
                    isOnLine: 0,
                    nickName:'',
                    mobile:'',
                    userName:decodeURI(this.GetQueryString('email')||''),
                    openId:this.GetQueryString('partnerId')||'',
                    customerId:''
                },
            }
        },
        components:{
            defaultSec
        },
        mounted(){
            console.log('openId:');
            console.log( this.GetQueryString('partnerId')||'');
            console.log('用户名:');
            console.log(decodeURI(this.GetQueryString('email')||''))
            this.getCustomerLineList()
        },
        methods: {
            getCustomerLineList() {
                this.loading = true;
                this.$apiGet('lechun-customers/customer/getCustomerList',this.formInline).then(res => {
                    this.loading = false;
                    this.customerInfo = res.list.length>0?[res.list[0]]:[{}]
                    this.formInline.customerId = this.customerInfo[0].customerId?this.customerInfo[0].customerId:''
                })
            },
            login(){
                if(window.localStorage.getItem("loginUser")==null||window.localStorage.getItem("loginUser")=="null"){
                    this.$apiGet('lechun-baseservice/auth/loginBySobot',{userName:this.formInline.userName,sign:''}).then(res=>{
                        console.log(res)
                        this.$message({
                            showClose: true,
                            message: '登录成功',
                            type: 'success'
                        });
                        window.localStorage.setItem('loginUser',this.formInline.userName);
                        this.getCustomerLineList();
                    })
                }else {
                    this.getCustomerLineList();
                }
            },
            handleCmsDetail() {
                console.log(this.formInline.customerId)
                if(this.formInline.customerId==''){
                    this.$notify({
                        title: '错误',
                        message: '用户不存在,请重试',
                        type: 'error'
                    });
                }else{
                    this.$router.push({path:'/userList/:id',name:'userDetail',params:{id:this.formInline.customerId}})
                }
            },
            getLink(stat){
                let returnUrl = ''
                if(stat=='1'){
                    returnUrl = `${window.location.origin}/userDetail/${this.formInline.customerId}?email=${this.GetQueryString('email')||''}`
                }else if(stat=='2'){
                    returnUrl = `http://erpnew.lechun.cc/mall_customer_edit.html?customerid=${this.formInline.customerId}`
                }
                return returnUrl
            },
            GetQueryString(name){
                let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
                let sp = 'userInfo'
                let u=window.location.href.split(sp)[1].substr(1);
                u= u.replace(/amp;/g,"");
                let r = u.match(reg);
                if(r!=null)return  decodeURIComponent(r[2]);
                return "";
            }
        }
    }
</script>

<style scoped>
</style>