<template>
    <div class="login-wrap" ref="login">
        <section class="login-container">
            <div class="login-container-header">
                <h3>乐纯管理平台登录</h3>
            </div>
            <div class="login-container-body">
                <el-form ref="form"
                         :model="form"
                         status-icon
                         hide-required-asterisk
                         :rules="rules" label-width="60px">
                    <el-form-item label="账号" prop="userName">
                        <el-input v-model="form.userName" placeholder="请输入账号"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="passWord">
                        <el-input v-model="form.passWord" type="password" show-password placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <el-form-item v-if="showValidateCode!='0'" label="验证码" prop="imageValidateCode">
                        <el-input class="img-input" v-model="form.imageValidateCode" placeholder="请输入图片验证码">
                            <img  slot="append" @click="getCaptcha" style="width: auto;height: 38px;display: block" :src="imgCode"/>
                        </el-input>
                    </el-form-item>
                </el-form>
<!--                <div class="forget-password">-->
<!--                    <span @click="forgetPassword">忘记密码</span>-->
<!--                </div>-->
                <div style="text-align: center">
                    <el-button type="primary" @click="onSubmit">立即登录</el-button>
                </div>

            </div>
        </section>
        <forgetPassword ref="forgetPasswordDialog" @closeDialog="closeDialog"></forgetPassword>
        <editPassword ref="editPasswordDialog" @closeEditDialog="closeEditDialog"></editPassword>
    </div>
</template>

<script>
    import crypto from 'crypto'
    import forgetPassword from "@c/forgetPassword";
    import editPassword from "@c/editPassword";
    export default {
        data() {
            return {
                imgCode:'',
                showValidateCode:'',
                form: {
                    userName:'',
                    passWord:'',
                    imageValidateCode:null
                },
                rules: {
                    userName: [
                        { required:true,message:'账号不能为空' }
                    ],
                    passWord: [
                        { required:true,message:'密码不能为空' }
                    ],
                    imageValidateCode: [
                        { required:true,message:'图片验证码不能为空', trigger: 'blur' }
                    ],
                }
            }
        },
        components:{
            forgetPassword,editPassword
        },
        mounted(){
            this.getCaptcha()
            this.$refs.login.onkeydown = (e)=> {
                e = e || window.event;
                if(e.keyCode == 13) {
                    this.onSubmit()
                }
            }
        },
        methods: {
            onSubmit() {
                console.log('submit!');

                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.$apiGet('lechun-baseservice/auth/login',{
                            userName:this.form.userName,
                            passWord:this.getmd5(this.form.passWord),
                            imageValidateCode:this.form.imageValidateCode||null}).then(res=>{
                            console.log(res)
                            this.$message({
                                showClose: true,
                                message: '登录成功',
                                type: 'success'
                            });
                            window.localStorage.setItem('loginUser',this.form.userName);
                            window.localStorage.setItem('userId',res.userId);
                            if(res.simplePwd == 1){
                                this.$alert('您的密码已过期，建议修改密码', '提示', {
                                    confirmButtonText: '去修改',
                                    type:'warning',
                                    showClose:false,
                                    center:true,
                                    callback: action => {
                                        this.$refs.editPasswordDialog.openEditDialog()
                                    }
                                });
                            }else{
                                let returnPage = this.$route.query.returnPage
                                if(returnPage){
                                    window.location.replace(decodeURIComponent(returnPage))
                                }else {
                                    this.$router.push('dashboard')
                                }
                            }

                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });

            },
            getCaptcha(){
                this.$apiGet('lechun-baseservice/auth/captcha',{}).then(res=>{
                    console.log(res)
                    this.imgCode = res.image||''
                    this.showValidateCode = res.showValidateCode

                })
            },
            getmd5(params){
                let md5 = crypto.createHash("md5");
                md5.update(params);
                return md5.digest('hex');
            },
            forgetPassword(){
                this.$refs.forgetPasswordDialog.openForgetDialog()
            },
            closeDialog(){
                this.getCaptcha()
            },
            closeEditDialog(){
                let returnPage = this.$route.query.returnPage
                if(returnPage){
                    window.location.href = decodeURIComponent(returnPage)
                }else {
                    this.$router.push('dashboard')
                }
            }
        }
    }
</script>


<style lang="scss" rel="stylesheet/scss">
    .login-wrap{
        width: 100%;
        height: 100%;
        background: url("../assets/images/blur-bg.jpg");
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        .login-container{
            width: 480px;
            background-color: rgba(0,0,0,.5);
            border: none;
            border-radius: 5px;
            position: relative;
            margin-bottom: 24px;
            box-shadow: 0 5px 5px 0 rgba(0,0,0,.25);
            .el-form-item__label{
                color: #ffffff;
            }
            &-header{
                border-bottom: 1px solid rgba(0,0,0,.12);
                line-height: 55px;
                box-shadow: 0 1px 0 0 rgba(255,255,255,.12);
                box-sizing: border-box;
                h3{
                    margin: 0;
                    font-size: 20px;
                    text-align: center;
                }
            }
            &-body{
                padding: 15px 22px;
                box-sizing: border-box;
                .forget-password{
                    display: flex;
                    justify-content: flex-end;
                    font-size: 12px;
                    margin: -10px 0 20px;
                    span{
                        cursor: pointer;
                    }
                }
                .img-input{
                    .el-input-group__append{
                        background: #ffffff;
                    }
                }
            }
        }

    }

</style>
