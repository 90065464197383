/*下载图片public函数
* @param{string} imgUrl 图片url
* @param{string} name 下载图片名称
* */

module.exports.downloadImage = (imgUrl='',name='')=>{
    let image = new Image()
    //解决跨越
    image.setAttribute('crossOrigin',"anonymous")
    image.onload = ()=>{
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let context = canvas.getContext("2d")
        context.drawImage(image,0,0,image.width,image.height)
        let url = canvas.toDataURL("image/png")
        let a = document.createElement('a')
        let event = new MouseEvent("click",{
            bubbles:true,
            cancelable:true,
            view:window
        })
        a.download = name
        a.href = url
        a.dispatchEvent(event)
    }
    image.src = imgUrl
}