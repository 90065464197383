<template>
    <el-container class="app-wrap">
        <vHeader></vHeader>
        <el-container class="main-wrap">
            <vAside></vAside>
            <el-main>
<!--                <vTabView></vTabView>-->
                <vMain></vMain>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import vHeader from '@c/Header'
    import vAside from '@c/aside'
    import vMain from '@c/appMain'
    import vTabView from '@c/tabView'

    export default({
        data(){
            return {}
        },
        components:{
            vHeader,vAside,vMain,vTabView
        },
        beforeCreate() {
            console.log('ff1',this.$route)
            if(this.$route.path=='/'||this.$route.path=='/dashboard'){
                this.$router.replace({path:'/csms'})
            }
            if(this.$route.path=='/dashboardscrm'){
                this.$router.replace({path:'/scrm'})
            }

        }
    })
</script>
<style  lang="scss" rel="stylesheet/scss">
@import "../assets/css/mixin";
    .el-container{
        width: 100%;
        height:100%;
    }
    .app-wrap{
        @include app_bg();
        background-size: cover;
    }
    .main-wrap{
        padding-top: 60px;
    }
</style>